import { motion } from "framer-motion";
import { format } from "date-fns";
import { Calendar, Users, Tag } from "lucide-react";
import { useState } from "react";
import { ComboPassRegistrationModal } from "./ComboPassRegistrationModal";
import { useAuth } from "../../contexts/AuthContext";

interface ComboPassCardProps {
  comboPass: {
    id: string;
    title: string;
    description: string[];
    price: number;
    image_url: string;
    valid_till: string;
    max_registrations: number;
    current_registrations: number;
    is_active: boolean;
    club_ids: string[];
  };
  clubs: {
    clubName: string;
    id: string;
    category: string;
    clubPassPrice: number;
    logo: string;
  }[];
}

export const ComboPassCard = ({ comboPass, clubs }: ComboPassCardProps) => {
  const { user } = useAuth();
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const isIITMStudent = user?.email_primary?.endsWith("smail.iitm.ac.in");

  return (
    <>
      {/* <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        whileInView={{ opacity: 1, scale: 1 }}
        whileHover={{ y: -5 }}
        className="bg-white dark:bg-gray-800 rounded-xl shadow-md overflow-hidden border border-gray-100 dark:border-gray-700"
      >
        <div className="relative">
          <img
            src={comboPass.image_url}
            alt={comboPass.title}
            className="w-full h-48 object-cover"
          />
          {!comboPass.is_active && (
            <div className="absolute inset-0 bg-black/50 flex items-center justify-center">
              <span className="text-white font-semibold px-4 py-2 bg-red-500 dark:bg-red-600 rounded-md">
                Not Active
              </span>
            </div>
          )}
        </div>

        <div className="p-6">
          <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-2">
            {comboPass.title}
          </h3>
          
          <div className="space-y-3 mb-4">
            <div className="flex items-center text-gray-600 dark:text-gray-300">
              <Calendar className="w-5 h-5 mr-2" />
              <span>Valid till {format(new Date(comboPass.valid_till), 'PPP')}</span>
            </div>
            
            <div className="flex items-center text-gray-600 dark:text-gray-300">
              <Users className="w-5 h-5 mr-2" />
              <span>
                {comboPass.current_registrations}/{comboPass.max_registrations} registered
              </span>
            </div>
            
            <div className="flex items-center text-gray-600 dark:text-gray-300">
              <Tag className="w-5 h-5 mr-2" />
              <span className="font-semibold">
                {isIITMStudent ? 'Free' : `₹${comboPass.price}`}
              </span>
            </div>
          </div>

          <div className="mb-6">
            <h4 className="font-semibold text-gray-900 dark:text-white mb-2">
              Included Clubs:
            </h4>
            <div className="flex flex-wrap gap-2">
              {clubs.map((club) => (
                <span
                  key={club.id}
                  className="px-3 py-1 bg-purple-50 dark:bg-purple-900/50 text-purple-700 dark:text-purple-300 rounded-full text-sm"
                >
                  {club.clubName}
                </span>
              ))}
            </div>
          </div>

          <button
            onClick={() => setShowRegistrationModal(true)}
            disabled={!comboPass.is_active}
            className={`w-full py-2 px-4 rounded-lg font-semibold transition-colors ${
              comboPass.is_active
                ? 'bg-purple-600 text-white hover:bg-purple-700 dark:bg-purple-700 dark:hover:bg-purple-800'
                : 'bg-gray-100 dark:bg-gray-700 text-gray-400 dark:text-gray-500 cursor-not-allowed'
            }`}
          >
            Get Combo Pass
          </button>
        </div>
      </motion.div>

      <ComboPassRegistrationModal
        isOpen={showRegistrationModal}
        onClose={() => setShowRegistrationModal(false)}
        comboPass={comboPass}
        clubs={clubs}
      /> */}

      <div className="flex flex-col justify-center items-center ">
        <div className="md:w-[60vw] w-[90vw] mb-[2vh]  bg-[#ffe523] rounded-2xl flex flex-col md:flex-row justify-between px-[3vw] py-[2vh]  text-left items-center">
          <div>
            <h1 className="md:text-3xl text-2xl font-unlock text-[#]">{comboPass.title}</h1>
            <div>
              <p className="mb:text-xl text-lg font-bold">
                {" "}
                {`₹${comboPass.price}`}
              </p>
              <p className="text-lg">{comboPass.description[0]}</p>
            </div>
            <p className="flex flex-row gap align-center items-center mt-[10px]">
              <strong>Included clubs:</strong>{" "}
              <p className=" ml-[10px] px-2 py-1 text-white">
                {" "}
                {clubs.map((club) => (
                  <span
                    key={club.id}
                    className="px-3 py-1 mr-2 bg-[#000000] rounded-xl  text-white "
                  >
                    {club.clubName}
                  </span>
                ))}
              </p>
            </p>
          </div>
          <button
            onClick={() => setShowRegistrationModal(true)}
            disabled={!comboPass.is_active}
            className={`min-w-[160px] h-[40px] bg-[#c20203] rounded-2xl text-white hover:scale-100 md:mt-[] mt-[20px] ${
              comboPass.is_active
                ? " text-white hover:bg-red-700 dark:bg-red-700 dark:hover:bg-red-800"
                : "bg-gray-100 dark:bg-gray-700 text-gray-400 dark:text-gray-500 cursor-not-allowed"
            }`}
          >
            Get combo pass
          </button>
        </div>
      </div>
      <ComboPassRegistrationModal
        isOpen={showRegistrationModal}
        onClose={() => setShowRegistrationModal(false)}
        comboPass={comboPass}
        clubs={clubs}
      />
    </>
  );
};
