import { useParams } from "react-router-dom";
import { useState } from "react";
import {
  useGetClubsQuery,
  useGetEventByClubQuery,
} from "../../generated/graphql";
import { ClubCard } from "./ClubCard";
import { EventsList } from "./EventsList";
import { ClubPassCard } from "./ClubPassCard";
import Navbar from "../navbar/navbar";

export default function CategoryClubs() {
  const { category } = useParams();
  const [selectedClubId, setSelectedClubId] = useState<string | null>(null);
  const { data, loading } = useGetClubsQuery();
  const { data: eventData } = useGetEventByClubQuery({
    variables: { clubId: selectedClubId || "" },
    skip: !selectedClubId,
  });

  if (loading) return null;

  const filteredClubs = data?.getClubs?.filter(
    (club: any) => club.category.toLowerCase() === category?.toLowerCase()
  );

  const selectedClub = filteredClubs?.find(
    (club: any) => club.id === selectedClubId
  );

  return (
    <div
      className="min-h-screen relative overflow-hidden"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/Rectangle.png)`,
      }}
    >
      <Navbar />

      <img
        src="/cd.png"
        alt="Right Top Image"
        className="absolute top-15 right-[-10%] lg:block hidden animate-rotateimage"
        style={{ width: "350px" }}
      />

      <div>
        <h1 className="text-center md:text-7xl text-4xl font-bold font-unlock text-[#FFFB00] mb-8 pt-8 capitalize">
          {category} Clubs
        </h1>

        <div className="flex flex-col md:flex-row lg:flex-row lg:gap-20 md:gap-5 overflow-hidden md:overflow-hidden">
          {/* Clubs List - Left Side */}
          <div
            className="flex flex-row mt-0 lg:mt-[0vh] md:mt-30 md:flex-col lg:flex-col md:w-56 md:ml-12 ml-2 w-[98%] gap-8 
               overflow-y-auto scrollbar-hide sm:overflow-y-auto sm:scrollbar-hide lg:overflow-y-visible"
          >
            {filteredClubs?.map((club: any) => (
              <ClubCard
                key={club.id}
                {...club}
                onSelect={setSelectedClubId}
                selected={club.id === selectedClubId}
              />
            ))}
          </div>

          {/* Club Events - Right Side */}
          <div className="lg:col-span-2 rounded-xl p-6 pt-[0%] shadow-sm flex flex-col dark:border-gray-700">
            {selectedClub ? (
              <div>
                <div className="flex items-center gap-4 mb-6">
                  <div>
                    <h2 className="text-3xl md:text-6xl font-bold font-unlock text-gray-900 dark:text-white pt-9">
                      {selectedClub.clubName} Events
                    </h2>
                  </div>
                </div>

                <div className="mb-8">
                  <ClubPassCard
                    id={selectedClub.id}
                    clubName={selectedClub.clubName}
                    clubPassPrice={selectedClub.clubPassPrice}
                    events={{ events: selectedClub.events || [] }}
                    logo={selectedClub.logo || ""}
                    category={selectedClub.category}
                    contactNumber={selectedClub.ContactNumber || ""}
                    contactName={selectedClub.ContactName || ""}
                  />
                </div>

                <EventsList
                  selectedCategory={null}
                  selectedClubId={selectedClubId}
                  onClubSelect={setSelectedClubId}
                />
              </div>
            ) : (
              <div className="text-left text-white pl-[7%] font-unlock md:pt-0 pt-5 w-[150%]">
                Select a club to view its events
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
