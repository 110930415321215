interface RazorpayOptions {
  key: string;
  amount: number;
  currency: string;
  name: string;
  description: string;
  order_id: string;
  handler: (response: any) => void;
  prefill?: {
    email?: string;
    contact?: string;
  };
}

declare global {
  interface Window {
    Razorpay: any;
  }
}

export const loadRazorpay = () => {
  if (!window.Razorpay) {
    console.error('Razorpay script not loaded');
    throw new Error('Razorpay script not loaded');
  }
  return window.Razorpay;
};

export const getRazorpay = loadRazorpay;