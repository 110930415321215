import React, { useState } from "react";
import { motion } from "framer-motion";
import { HiMenu, HiX } from "react-icons/hi"; // Importing icons for the hamburger and cross
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const { isAuthenticated, logout, user } = useAuth();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleAuthAction = async () => {
    if (isAuthenticated) {
      try {
        await logout();
        navigate("/auth");
      } catch (error) {
        console.error("Logout failed:", error);
      }
    } else {
      navigate("/auth");
    }
  };

  return (
    <div className="w-[100vw] flex justify-between items-center p-4 px-10 relative">
      {/* Logo */}
      <img src="/logo.png" className="w-[70px] md:w-[100px] z-20 " alt="logo" />

      {/* Desktop Navigation Links */}
      <div className="hidden md:flex flex-row gap-14 md:text-2xl  text-xs text-[#ffffff]">
        {/* Existing links for desktop view */}

        <motion.a
          className="cursor-pointer"
          whileHover={{
            scale: 1.2,
            textDecoration: "underline",
            transition: { duration: 0.3, ease: "easeInOut" },
          }}
          onClick={() => navigate("/")}
        >
          Competitions
        </motion.a>
        <motion.a
          className="cursor-pointer  h-[40px] items-center text-2xl"
          onClick={()=>navigate("/dashboard")}
          whileHover={{
            scale: 1.1,
            textDecoration: "underline",
            transition: { duration: 0.3, ease: "easeInOut" },
          }}
        
        >
          Registrations
        </motion.a>
        <motion.a
          className="cursor-pointer"
          whileHover={{
            scale: 1.2,
            textDecoration: "underline",
            transition: { duration: 0.3, ease: "easeInOut" },
          }}
          href="https://join.saarang.org/milans"
          target="_blank"
        >
          Milans
        </motion.a>
        {/* More links */}
        <motion.a
          className="cursor-pointer"
          whileHover={{
            scale: 1.1,
            textDecoration: "underline",
            transition: { duration: 0.3, ease: "easeInOut" },
          }}
          href="https://join.saarang.org/accommodation"
          target="_blank"
        >
          Accommodation
        </motion.a>

        <motion.a
          className="cursor-pointer  h-[40px] items-center text-2xl"
          
          whileHover={{
            scale: 1.1,
            textDecoration: "underline",
            transition: { duration: 0.3, ease: "easeInOut" },
          }}
          href="https://join.saarang.org/"
          target="_blank"
        >
          Ambassador
        </motion.a>
        <motion.a
          className="cursor-pointer  h-[40px] items-center text-2xl"
          
          whileHover={{
            scale: 1.1,
            textDecoration: "underline",
            transition: { duration: 0.3, ease: "easeInOut" },
          }}
          href="https://sales.saarang.org/#proshows"
          target="_blank"
        >
          Tickets
        </motion.a>
      
      </div>
      <div className="">
        <motion.button
          whileHover={{
            scale: 1.2,
            transition: { duration: 0.3, ease: "easeInOut" },
          }}
          onClick={handleAuthAction}
          className="bg-[#fffb00] min-w-[150px] hidden sm:hidden md:block text-xl text-black rounded-xl px-[15px] py-[6px]"
        >
          {isAuthenticated ? "Logout" : "Sign in"}
        </motion.button>
      </div>

      {/* Hamburger Icon for Mobile View */}
      <div className="md:hidden z-20">
        <button onClick={toggleMenu} aria-label="Toggle menu">
          {isMenuOpen ? (
            <HiX className="text-5xl text-[#fffb00]" />
          ) : (
            <HiMenu className="text-6xl text-[#fffb00]" />
          )}
        </button>
      </div>

      {/* Mobile Navigation Links */}
      {isMenuOpen && (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          className="absolute -top-3 pt-[12vh] z-10 left-0 w-full bg-black text-[#fffb00] flex flex-col gap-4 items-center md:hidden"
        >
          {/* Existing links for mobile view */}
          <motion.a
            className="cursor-pointer h-[40px] items-center text-2xl"
            onClick={()=>{navigate("/"); toggleMenu()}}
            whileHover={{
              scale: 1.1,
              textDecoration: "underline",
              transition: { duration: 0.3, ease: "easeInOut" },
            }}
          >
            Competitions
          </motion.a>
          <motion.a
            className="cursor-pointer  h-[40px] items-center text-2xl"
            onClick={()=>{navigate("/dashboard"); toggleMenu()}}
            whileHover={{
              scale: 1.1,
              textDecoration: "underline",
              transition: { duration: 0.3, ease: "easeInOut" },
            }}
          >
            Registrations
          </motion.a>
          <motion.a
            className="cursor-pointer  h-[40px] items-center text-2xl"
            onClick={toggleMenu}
            whileHover={{
              scale: 1.1,
              textDecoration: "underline",
              transition: { duration: 0.3, ease: "easeInOut" },
            }}
            href="https://join.saarang.org/"
            target="_blank"
          >
            Ambassador
          </motion.a>
          <motion.a
            className="cursor-pointer  h-[40px] items-center text-2xl"
            onClick={toggleMenu}
            whileHover={{
              scale: 1.1,
              textDecoration: "underline",
              transition: { duration: 0.3, ease: "easeInOut" },
            }}
            href="https://join.saarang.org/milans"
            target="_blank"
          >
            Milans
          </motion.a>
          <motion.a
            className="cursor-pointer  h-[40px] items-center text-2xl"
            onClick={toggleMenu}
            whileHover={{
              scale: 1.1,
              textDecoration: "underline",
              transition: { duration: 0.3, ease: "easeInOut" },
            }}
            href="https://sales.saarang.org/#proshows"
            target="_blank"
          >
            Tickets
          </motion.a>
          
          <button
            onClick={handleAuthAction}
            className="bg-[#fffb00] w-[100vw] h-[50px] text-black text-2xl px-[15px] pb-[8px]"
          >
            {isAuthenticated ? "Logout" : "Sign in"}
          </button>
        </motion.div>
      )}
    </div>
  );
};

export default Navbar;
