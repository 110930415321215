import React, { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
//import EmailVerification from "./EmailVerification";
const GoogleLogo = require("../../assets/google.png");
// import GoogleIcon from "../../assets/google-icon.svg";
// import './Auth.css'

const Auth: React.FC = () => {
  const {
    signInWithGoogle,
    signInWithEmailAndPassword,
    signUpWithEmailAndPassword,
  } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSignUp, setIsSignUp] = useState(false);
  const [username, setUsername] = useState("");
  const [showVerification, setShowVerification] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    if (isVerified) {
      navigate("/profile/setup");
    }
  }, [isVerified, navigate]);

  const handleEmailAuth = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (isSignUp && (!email || !password || !username)) {
        toast.error("Please fill in all fields");
        return;
      } else if (!isSignUp && (!email || !password)) {
        toast.error("Please enter both email and password");
        return;
      }

      if (isSignUp && password.length < 6) {
        toast.error("Password should be at least 6 characters");
        return;
      }

      if (isSignUp) {
        await signUpWithEmailAndPassword(email, password, username);
        // toast.success("Please check your email for verification link");
        navigate("/profile/setup");
        return;
      }

      const user = await signInWithEmailAndPassword(email, password);

      if (!user) {
        throw new Error("Authentication failed");
      }
      console.log(user);

      if (!user.verified) {
        setShowVerification(true);
        toast("Please verify your email to continue");
        console.log("Please verify your email to continue");
      }

      if (user.new_user === true) {
        toast.success(
          "Welcome to Saarang! Please complete your profile setup."
        );
        navigate("/profile/setup");
      } else if (user.new_user === false) {
        toast.success("Successfully signed in!");
        navigate("/events");
      } else {
        toast.error("Something went wrong");
      }
    } catch (error: any) {
      const errorMessage = (() => {
        switch (error.code) {
          case "auth/invalid-email":
            return "Invalid email address";
          case "auth/invalid-credential":
            return "Invalid email or password";
          case "auth/user-not-found":
            return "No account found with this email";
          case "auth/wrong-password":
            return "Incorrect password";
          case "auth/email-already-in-use":
            return "An account already exists with this email";
          case "auth/weak-password":
            return "Password should be at least 6 characters";
          case "auth/too-many-requests":
            return "Too many failed attempts. Please try again later";
          default:
            return error.message || "Authentication failed. Please try again.";
        }
      })();
      console.log(error);
      toast.error(errorMessage);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const user = await signInWithGoogle();

      if (!user) {
        throw new Error("Authentication failed");
      }
      console.log("hello :" + user.mobile_number_primary);
      if (user?.mobile_number_primary?.length === 0) {
        toast.success(
          "Welcome to Saarang! Please complete your profile setup."
        );
        navigate("/profile/setup");
        // } else if (user?.new_user === false) {
        //   toast.success("Successfully signed in!");
        //   navigate("/events");
      } else {
        toast.success("Successfully signed in!");
        navigate("/events");
      }
    } catch (error: any) {
      console.error("Error signing in:", error);
      toast.error(
        error.message || "Failed to sign in with Google. Please try again."
      );
    }
  };

  return (
    <div className="relative h-[100vh] w-[100%] flex items-center justify-center bg-[#c1204b] overflow-hidden">
      <div className="absolute w-[100%] h-screen transform rotate-[125deg] overflow-hidden left-[-40%] hidden lg:block ">
        <div
          className="absolute top-0 left-0 w-[150vw] h-[50%] bg-cover bg-no-repeat animate-slide"
          style={{ backgroundImage: "url('/film1.png')" }}
        ></div>
      </div>

      <div className="absolute w-[100%] visible h-screen transform rotate-[50deg] overflow-hidden left-[30%] hidden lg:block">
        <div
          className="absolute top-0 left-0 w-[150vw] h-[50%] bg-cover bg-no-repeat animate-slide"
          style={{ backgroundImage: "url('/film2.png')" }}
        ></div>
      </div>
      <div className="absolute w-[100%] h-screen transform rotate-[-60deg] overflow-hidden left-[40%] hidden lg:block">
        <div
          className="absolute top-0 left-0 w-[150vw] h-[50%] bg-cover bg-no-repeat animate-slide"
          style={{ backgroundImage: "url('/film3.png')" }}
        ></div>
      </div>
      <div className="absolute w-[100%] h-screen transform rotate-[50deg] overflow-hidden left-[-40%] hidden lg:block">
        <div
          className="absolute top-0 left-0 w-[150vw] h-[50%] bg-cover bg-no-repeat animate-slide"
          style={{ backgroundImage: "url('/film4.png')" }}
        ></div>
      </div>

      {/* <div
      
       className="absolute -top-10 -left-50 right-0 mx-auto mt-4 z-50  bg-cover bg-center "
    >  <img src="./cd.png" className="w-[30vw] animate-rotate-image" /></div> */}
      <div className="md:w-[28rem] w-[90%] space-y-8 p-4 pb-6 bg-[#febd02] z-10 rounded-3xl shadow-lg">
        <div className="text-center">
          <h2 className="mt-6 text-5xl font-bold font-roboto-condensed text-white">
            Welcome to Saarang
          </h2>
          <p className="mt-2 text-sm text-[#000000]">
            Please sign in to continue
          </p>
        </div>

        <form
          onSubmit={handleEmailAuth}
          className="mt-8 space-y-6 flex flex-col justify-center items-center"
        >
          {isSignUp && (
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Username"
              required
              className="appearance-none rounded-2xl relative block w-full h-[55px] px-3 py-2 border-2 border-[#c1204b] placeholder-white bg-[#febd02] text-gray-900  focus:bg-[#febd02]"
            />
          )}
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email address"
            required
            className="appearance-none rounded-2xl relative block w-full h-[55px] px-3 py-2 border-2 border-[#c1204b] placeholder-white bg-[#febd02] text-gray-900  focus:bg-[#febd02]"
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
            className="appearance-none rounded-2xl relative block w-full h-[55px] px-3 py-2 border-2 border-[#c1204b] placeholder-white bg-[#febd02] text-gray-900  focus:bg-[#febd02]"
          />
          <button
            type="submit"
            className="w-[70%] py-3 px-4 border border-transparent rounded-3xl shadow-sm text-sm font-medium text-white bg-[#c1204b] hover:bg-[#8d1737] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {isSignUp ? "Sign Up" : "Sign In"}
          </button>
        </form>

        <div className="text-center">
          <button
            onClick={() => setIsSignUp(!isSignUp)}
            className="text-sm text-[#000] font-roboto-condensed hover:text-indigo-500"
          >
            {isSignUp
              ? "Already have an account? Sign In"
              : "Need an account? Sign Up"}
          </button>
        </div>

        <div className="relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-[#c1204b]" />
          </div>
          <div className="relative flex justify-center text-sm">
            {/* <span className="px-2  mt-[20px]   text-[#000]">
              O
            </span> */}
          </div>
        </div>

        <button
          onClick={handleGoogleSignIn}
          className="w-[100%] flex flex-row justify-center items-center gap-4 py-3 px-4 border border-transparent rounded-xl shadow-sm text-sm font-medium text-white bg-[#c1204b] hover:bg-[#8d1737] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <img src={GoogleLogo} alt="Google logo" className="w-5 h-5" />
          <p>Or Continue with Google</p>
        </button>
      </div>
    </div>
  );
};

export default Auth;
