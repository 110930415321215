import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import {
  useCreateComboPassRegistrationMutation,
  useCreateDirectComboPassRegistrationMutation,
} from "../../generated/graphql";
import { useAuth } from "../../contexts/AuthContext";
import { loadRazorpay } from "../../utils/razorpay";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
interface ComboPassRegistrationModalProps {
  isOpen: boolean;
  onClose: () => void;
  comboPass: {
    id: string;
    title: string;
    price: number;
    description: string[];
  };
  clubs: {
    clubName: string;
    id: string;
  }[];
}

export const ComboPassRegistrationModal = ({
  isOpen,
  onClose,
  comboPass,
  clubs,
}: ComboPassRegistrationModalProps) => {
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const [createRegistration] = useCreateComboPassRegistrationMutation();
  const [createDirectComboPassRegistration] =
    useCreateDirectComboPassRegistrationMutation();
  const navigate = useNavigate();
  const handleRegistration = async () => {
    console.log("Starting combo pass registration process");
    if (!user) {
      console.log("No user found");
      toast.error("Please login to continue");
      return;
    }

    setLoading(true);
    try {
      const isIITMStudent = user.email_primary.endsWith("smail.iitm.ac.in");
      console.log(
        "User type:",
        isIITMStudent ? "IITM Student" : "External User"
      );
      const isVerified = user.verified;
      if (!isVerified) {
        toast.error("Please verify your email to continue");
        navigate("/sendVerificationEmail");
        return;
      }
      // if (isIITMStudent) {
      //   console.log("Processing direct combo pass registration");
      //   const { errors } = await createDirectComboPassRegistration({
      //     variables: {
      //       userId: user.user_id,
      //       comboPassId: comboPass.id,
      //     },
      //   });

      //   if (errors) {
      //     throw new Error(errors[0].message);
      //   }

      //   toast.success("Successfully registered for combo pass!");
      //   onClose();
      //   return;
      // } else {
        console.log("Loading Razorpay...");
        const Razorpay = await loadRazorpay();
        console.log("Razorpay loaded successfully");

        console.log("Creating combo pass registration record...");
        const { data } = await createRegistration({
          variables: {
            userId: user.user_id,
            comboPassId: comboPass.id,
          },
        });
        console.log("Registration record created:", data);

        const registration = data?.createComboPassRegistration;
        if (!registration?.razorpay_order_id) {
          throw new Error("No order ID received from registration");
        }

        const options = {
          key:
            process.env.NEXT_PUBLIC_RAZORPAY_KEY_ID ||
            "rzp_live_VgVO9uzPsxViN5",
          amount: comboPass.price * 100,
          currency: "INR",
          name: "Event Registration",
          description: `Combo Pass`,
          order_id: registration.razorpay_order_id,
          handler: function (response: any) {
            console.log("Payment success response:", response);
            toast.success(
              "Payment successful! You're registered for the combo pass."
            );
            onClose();
          },
          prefill: {
            email: user.email_primary,
            contact: user.mobile_number_primary,
          },
          modal: {
            ondismiss: function () {
              console.log("Payment modal closed by user");
            },
          },
        };

        console.log("Initializing Razorpay with options:", {
          amount: options.amount,
          orderId: options.order_id,
        });
        const paymentObject = new (Razorpay as any)(options);
        console.log("Opening payment modal");
        paymentObject.open();
      // }
    } catch (error: any) {
      console.error("Registration error details:", error);
      toast.error(
        error.message || "Failed to process registration. Please try again."
      );
    } finally {
      setLoading(false);
      console.log("Registration process completed");
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Confirm Combo Pass Registration
                </Dialog.Title>

                <div className="mt-4">
                  <p className="text-sm text-gray-500 mb-4">
                  After purchasing this pass, you can register for any event hosted by these clubs without paying extra fees.
                  </p>
               
                  <ul className="list-disc list-inside mb-4 space-y-1">
                    {clubs.map((club) => (
                      <li key={club.id} className="text-gray-700">
                        {club.clubName}
                      </li>
                    ))}
                  </ul>
                  <div className="text-sm text-gray-500 mb-6">
                    <p>
                      Total Amount:{" "}
                      {user?.email_primary?.endsWith("smail.iitm.ac.in")
                        ? "Free"
                        : `₹${comboPass.price}`}
                    </p>
                  </div>
                </div>

                <div className="mt-4 flex justify-end gap-3">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-500 focus-visible:ring-offset-2"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className={`inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white hover:bg-purple-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-500 focus-visible:ring-offset-2 ${
                      loading ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    onClick={handleRegistration}
                    disabled={loading}
                  >
                    {loading
                      ? "Processing..."
                      : user?.email_primary?.endsWith("smail.iitm.ac.in")
                      ? "Register"
                      : "Proceed to Pay"}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
