import { ApolloClient, InMemoryCache } from "@apollo/client";
let api_url = 'https://api.saarang.org/graphql'

// let api_url = "http://localhost:7000/graphql";

const client = new ApolloClient({
  uri: api_url,
  cache: new InMemoryCache(),
  credentials: "include",
});

export default client;
