import { useQuery } from "@apollo/client";
import { motion } from "framer-motion";
import { useGetAllComboPassesQuery } from "../../generated/graphql";
import { ComboPassCard } from "./ComboPassCard";

export const ComboPassSection = () => {
  const { data, loading } = useGetAllComboPassesQuery();

  if (loading) return null;

  return (
    <section className="mb-16">
      <motion.h2
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        className="md:text-8xl text-4xl  font-bold text-[#FFFB00] mb-[15vh] font-unlock tracking-wide text-center"
      >
        COMBO PASSES
      </motion.h2>

      <div className="flex flex-col gap-6 mb-[20vh]">
        {data?.getAllComboPasses?.map((passData: any) => (
          <ComboPassCard
            key={passData.comboPass.id}
            comboPass={passData.comboPass}
            clubs={passData.clubs}
          />
        ))}
      </div>
    </section>
  );
};
