import { useGetEventsQuery, useGetClubsQuery } from "../../generated/graphql";
import { EventCard } from "./EventCard";
import { LoadingSpinner } from "../ui/LoadingSpinner";
import { ClubCard } from "./ClubCard";

interface EventsListProps {
  selectedCategory: string | null;
  selectedClubId: string | null;
  onClubSelect: (clubId: string) => void;
}

export const EventsList = ({ selectedCategory, selectedClubId, onClubSelect }: EventsListProps) => {
  const { data: eventsData, loading: eventsLoading, error } = useGetEventsQuery();
  const { data: clubsData, loading: clubsLoading } = useGetClubsQuery();

  if (eventsLoading || clubsLoading) return <LoadingSpinner />;
  if (error) return <div>Error loading events</div>;

  if (!selectedClubId) {
    const filteredClubs = selectedCategory
      ? clubsData?.getClubs?.filter((club: any) => club.category === selectedCategory)
      : clubsData?.getClubs;

    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredClubs?.map((club: any) => (
          <ClubCard key={club.id} {...club} onSelect={onClubSelect} />
        ))}
      </div>
    );
  }

  const clubEvents = eventsData?.getEvents?.filter(
    (event: any) => event.Club.id === selectedClubId
  );

  return (
<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 justify-items-center  p-4 ">
  {clubEvents?.map((event: any) => (
    <EventCard key={event.id} {...event} />
  ))}
</div>

  );
};
