import { motion } from "framer-motion";
import { useState } from "react";
import { ClubPassRegistrationModal } from "./ClubPassRegistrationModal";
import { useAuth } from "../../contexts/AuthContext";

interface ClubPassCardProps {
  id: string;
  clubName: string;
  logo: string;
  clubPassPrice: number;
  category: string;
  events:
    | {
        Club: {
          events: Array<{
            id: string;
            title: string;
            event_date: string;
          }>[];
        };
      }
    | { events: Array<any> };
  contactNumber: string;
  contactName: string;
}

export const ClubPassCard = ({
  id,
  clubName,
  logo,
  clubPassPrice,
  category,
  events,
  contactNumber,
  contactName,
}: ClubPassCardProps) => {
  const { user } = useAuth();
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);

  const upcomingEvents =
    "Club" in events ? events.Club.events : events.events || [];
  const isIITMStudent = user?.email_primary?.endsWith("smail.iitm.ac.in");

  return (
    <>
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        whileInView={{ opacity: 1, scale: 1 }}
        whileHover={{ y: -5 }}
        className="flex bg-none rounded-xl"
      >
        <div className="">
          <div className="mb-4 flex items-center gap-4  p-4 md:flex-row md:items-start flex-col sm">
            {/* Get Club Pass Button */}

            <div className="flex  items-start gap-2 sm:gap-4">
              <div className="flex flex-row items-start gap-2 rounded-lg border border-white p-2 sm:p-4">
                <div className="text-lg text-white  font-unlock">
                  Contact Person:{" "}
                  <div className="text-lg text-yellow-300">{contactName}</div>
                </div>
                <div className="text-lg text-white font-unlock">
                  Phone Number{" "}
                  <div className="text-lg text-yellow-300">{contactNumber}</div>
                </div>
              </div>
            </div>
          {/* new */}
            {!isIITMStudent&&
            <div className="flex flex-row items-center gap-2 sm:gap-4 sm:flex-row  rounded-lg border border-white px-3">
              <h4 className="text-lg text-white  font-unlock  sm:p-4">
                Club Pass Price{" "}
                <div className="text-yellow-300 ">
                  {isIITMStudent ? "Free" : `₹${clubPassPrice}`}
                </div>
              </h4>
              <button
                onClick={() => setShowRegistrationModal(true)}
                className="px-4 py-2 bg-[#FDB951] text-black rounded-lg font-semibold hover:bg-yellow-600 transition-colors "
              >
                Get Club Pass
              </button>
            </div>}
            {/* new  */}
          </div>

          {/* Upcoming Events */}
          {upcomingEvents.length > 0 && (
            <div className="mb-6">
              <h4 className="font-semibold text-gray-900 mb-2">
                Upcoming Events:
              </h4>
              <ul className="space-y-2">
                {upcomingEvents.slice(0, 3).map((event) => (
                  <li
                    key={event.id}
                    className="text-sm text-gray-600 flex items-center"
                  >
                    <span className="w-2 h-2 bg-purple-500 rounded-full mr-2" />
                    {event.title}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </motion.div>

      {/* Club Pass Registration Modal */}
      <ClubPassRegistrationModal
        isOpen={showRegistrationModal}
        onClose={() => setShowRegistrationModal(false)}
        club={{
          id,
          clubName,
          clubPassPrice,
          upcomingEvents,
        }}
      />
    </>
  );
};
