import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import {
  useCreateSingleEventRegistrationMutation,
  useCreateDirectEventRegistrationMutation,
} from "../../generated/graphql";
import { useAuth } from "../../contexts/AuthContext";
import { loadRazorpay } from "../../utils/razorpay";
import { format } from "date-fns";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

interface EventRegistrationModalProps {
  isOpen: boolean;
  onClose: () => void;
  event: {
    id: string;
    title: string;
    price: number;
    event_date: string;
    event_location: string;
    Club: {
      clubName: string;
    };
  };
}

export const EventRegistrationModal = ({
  isOpen,
  onClose,
  event,
}: EventRegistrationModalProps) => {
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const [createRegistration] = useCreateSingleEventRegistrationMutation();
  const [createDirectRegistration] = useCreateDirectEventRegistrationMutation();
  const navigate = useNavigate();
  const handleRegistration = async () => {
    console.log("Starting event registration process");
    if (!user) {
      console.log("No user found, redirecting to auth");
      toast.error("Please login to continue");
      window.location.href = "/auth";
      return;
    }

    setLoading(true);
    try {
      const isIITMStudent = user.email_primary.endsWith("smail.iitm.ac.in");
      console.log(
        "User type:",
        isIITMStudent ? "IITM Student" : "External User"
      );
      const isVerified = user.verified;
      if (!isVerified) {
        toast.error("Please verify your email to continue");
        navigate("/sendVerificationEmail");
        return;
      }
      if (isIITMStudent) {
        console.log("Processing direct registration for IITM student");
        const { errors } = await createDirectRegistration({
          variables: {
            userId: user.user_id,
            eventsId: event.id,
          },
        });

        if (errors) {
          throw new Error(errors[0].message);
        }

        toast.success("Successfully registered for the event!");
        onClose();
        return;
      }

      console.log("Loading Razorpay...");
      const Razorpay = await loadRazorpay();
      console.log("Razorpay loaded successfully");

      console.log("Creating registration record...");
      const { data } = await createRegistration({
        variables: {
          userId: user.user_id,
          eventsId: event.id,
        },
      });
      console.log("Registration record created:", data);

      const registration = data?.createSingleEventRegistration;
      //new
      if (!registration?.paid) {
        toast.success("Successfully registered using your club pass!");
        onClose();
        return;
      }
      //new
      const options = {
        key:
          process.env.NEXT_PUBLIC_RAZORPAY_KEY_ID || "rzp_live_VgVO9uzPsxViN5",
        amount: event.price * 102,
        currency: "INR",
        name: "Event Registration",
        description: `Event`,
        order_id: registration?.razorpay_order_id,
        handler: function (response: any) {
          console.log("Payment success response:", response);
          toast.success("Payment successful! You're registered for the event.");
          onClose();
        },
        prefill: {
          email: user.email_primary,
          contact: user.mobile_number_primary,
        },
        modal: {
          ondismiss: function () {
            console.log("Payment modal closed by user");
          },
        },
      };

      console.log("Initializing Razorpay with options:", {
        amount: options.amount,
        orderId: options.order_id,
      });
      const paymentObject = new (Razorpay as any)(options);
      console.log("Opening payment modal");
      paymentObject.open();
    } catch (error: any) {
      console.error("Registration error details:", error);
      toast.error(
        error.message || "Failed to process registration. Please try again."
      );
    } finally {
      setLoading(false);
      console.log("Registration process completed");
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Confirm Event Registration
                </Dialog.Title>

                <div className="mt-4">
                  <div className="space-y-4">
                    <div>
                      <h4 className="font-medium text-gray-900">
                        {event.title}
                      </h4>
                      <p className="text-sm text-gray-500">
                        by {event.Club.clubName}
                      </p>
                     
                    </div>

                    <div className="text-sm text-gray-600">
                      <p>Date: {format(new Date(event.event_date), "PPP")}</p>
                      <p>Location: {event.event_location}</p>
                      <p className="font-medium">
                        Amount:{" "}
                        {user?.email_primary?.endsWith("smail.iitm.ac.in")
                          ? "Free"
                          : `₹${event.price}`}
                      </p>
                      {!user?.email_primary?.endsWith("smail.iitm.ac.in")&& <p>If you already have a valid club or combo pass, you can register without any additional payment.</p> }
                    </div>
                  </div>
                </div>

                <div className="mt-6 flex justify-end gap-3">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-red-600 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-red-50"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className={`inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white hover:bg-red-700 ${
                      loading ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    onClick={handleRegistration}
                    disabled={loading}
                  >
                    {loading
                      ? "Processing..."
                      : "Register"}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
