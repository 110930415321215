import { motion } from "framer-motion";

interface ClubCardProps {
  id: string;
  clubName: string;
  logo: string;
  category: string;
  description: string;
  contactNumber: number;
  contactName: string;
  onSelect: (clubId: string) => void;
  selected: boolean; // New prop to indicate if the card is selected
}

export const ClubCard = ({
  id,
  clubName,
  logo,
  category,
  description,
  contactNumber,
  contactName,
  onSelect,
  selected,
}: ClubCardProps) => {
  return (
    <div className="relative md:w-full md:h-20  cursor-pointer ">
      {/* Black Rectangle (Shadow - Bottom & Right) */}
      <motion.div
        className="absolute inset-0 bg-black  "
        style={{
          transform: "translate(8px, 8px)", // Offset to bottom-right
        }}
        animate={{
          y: selected ? 8 : 12, // Moves the shadow further down when selected
          x: selected ? 8 : 12, // Moves the shadow further to the right when selected
        }}
        transition={{ type: "spring", stiffness: 300 }}
      ></motion.div>

      {/* Yellow Rectangle (Main Card) */}
      <motion.div
        onClick={() => onSelect(id)}
        className={`relative z-10 md:w-full md:h-full w-[35vw] h-[8vh] md:h  shadow-md flex justify-center items-center text-center ${"bg-[#FDB951]"}`}
        animate={{
          y: selected ? 8 : 0, // Simulates pressing down
          x: selected ? 8 : 0, // Simulates pressing down to the right
        }}
        transition={{ type: "spring", stiffness: 300 }}
        whileHover={{
          y: selected ? 8 : -5, // Hover effect (lift when not selected)
          x: selected ? 8 : -5, // Hover effect (lift to the left when not selected)
        }}
      >
        <h3 className="text-2xl font-bold text-black">{clubName}</h3>
      </motion.div>
    </div>
  );
};
