import { EventsLayout } from "./EventsLayout";
import {
  useGetEventByClubQuery,
  useGetClubsQuery,
} from "../../generated/graphql";
import { EventsList } from "./EventsList";
import { ClubPassCard } from "./ClubPassCard";
export default function Workshops() {
  const { data } = useGetClubsQuery();
  const workshopClub = data?.getClubs?.find(
    (club: any) => club.category.toLowerCase() === "workshop"
  );

  const { data: eventData } = useGetEventByClubQuery({
    variables: { clubId: workshopClub?.id || "" },
    skip: !workshopClub?.id,
  });

  return (
    <EventsLayout>
      <div className="min-h-screen px-4 sm:px-6 lg:px-8 py-16">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">
            Workshops
          </h1>

          <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-sm border border-gray-100 dark:border-gray-700">
            {workshopClub ? (
              <div>
                <div className="flex items-center gap-4 mb-6">
                  <img
                    src={workshopClub.logo || ""}
                    alt={workshopClub.clubName}
                    className="w-20 h-20 rounded-full"
                  />
                  <div>
                    <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
                      {workshopClub.clubName}
                    </h2>
                  </div>
                </div>

                <div className="mb-8">
                  <ClubPassCard
                    contactNumber={workshopClub.ContactNumber || ""}
                    contactName={workshopClub.ContactName || ""}
                    id={workshopClub.id}
                    clubName={workshopClub.clubName}
                    clubPassPrice={workshopClub.clubPassPrice}
                    events={{ events: workshopClub.events || [] }}
                    logo={workshopClub.logo || ""}
                    category={workshopClub.category}
                  />
                </div>

                <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
                  Upcoming Workshops
                </h3>

                <EventsList
                  selectedCategory="workshop"
                  selectedClubId={workshopClub.id}
                  onClubSelect={() => {}}
                />
              </div>
            ) : (
              <div className="text-center text-gray-500 dark:text-gray-400">
                No workshops available at the moment
              </div>
            )}
          </div>
        </div>
      </div>
    </EventsLayout>
  );
}
