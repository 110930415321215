import { motion } from "framer-motion";

const fade = { initial: { opacity: 0 }, animate: { opacity: 1 } };

export const EventsLayout = ({ children }: { children: React.ReactNode }) => (
  <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
    <motion.div
      {...fade}
      className="relative bg-gradient-to-r from-purple-600 to-blue-600 text-white"
    >
      <div className="max-w-7xl mx-auto px-4 py-16 text-center">
        <h1 className="text-4xl font-bold mb-1">Events & Workshops</h1>
        <p className="text-xl mb-8">
          Discover amazing events and join our community
        </p>
        {/* <button className="px-8 py-3 bg-white text-purple-600 rounded-full hover:bg-opacity-90">
          Explore Now
        </button> */}
      </div>
    </motion.div>
    <div className="max-w-7xl mx-auto px-4 py-8">{children}</div>
  </div>
);
