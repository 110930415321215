import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { SendVerificationEmailDocument } from "../../generated/graphql";
import toast from "react-hot-toast";
import { useAuth } from "../../contexts/AuthContext";
const SendVerificationEmail: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const [error, setError] = useState<string | null>(null);
  const [sendVerificationEmail] = useMutation(SendVerificationEmailDocument);
  const { user } = useAuth();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    if (!user) {
      toast.error("Please login to continue");
      return;
    }

    try {
      await sendVerificationEmail({
        variables: {
          emailPrimary: user.email_primary,
        },
      });

      navigate("/");
    } catch (err) {
      const errorMessage =
        err instanceof Error
          ? err.message
          : "Failed to send verification email";
      setError(errorMessage);
      toast.error(errorMessage);
      console.error("Error sending verification email:", err);
    }
  };

  return (
    <div className="relative h-[100vh] w-[100%] flex items-center justify-center bg-[#c1204b] overflow-hidden">
      {/* Background decorative elements */}
      <div className="absolute w-[100%] h-screen transform rotate-[125deg] overflow-hidden left-[-40%] hidden lg:block ">
        <div
          className="absolute top-0 left-0 w-[150vw] h-[50%] bg-cover bg-no-repeat animate-slide"
          style={{ backgroundImage: "url('/film1.png')" }}
        ></div>
      </div>
      <div className="absolute w-[100%] visible h-screen transform rotate-[50deg] overflow-hidden left-[30%] hidden lg:block">
        <div
          className="absolute top-0 left-0 w-[150vw] h-[50%] bg-cover bg-no-repeat animate-slide"
          style={{ backgroundImage: "url('/film2.png')" }}
        ></div>
      </div>

      <div className="md:w-[28rem] w-[90%] space-y-8 p-4 pb-6 bg-[#febd02] z-10 rounded-3xl shadow-lg">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Email Verification
          </h2>
          {/* <p className="mt-2 text-center text-sm text-gray-600">
            Enter your email to receive a verification link
          </p> */}
        </div>

        {error && (
          <div className="mb-4 p-4 text-red-700 bg-red-100 rounded-md">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="w-full">
            <label className="block text-sm font-medium text-gray-700">
              Email Address
            </label>
            <input
              type="email"
              placeholder="Enter your email"
              className="appearance-none rounded-2xl relative block w-full h-[55px] px-3 py-2 border-2 border-[#c1204b] placeholder-black bg-[#febd02] text-gray-900 focus:bg-[#febd02]"
              value={user?.email_primary}
              disabled
            />
          </div>

          <div className="flex justify-center">
            <button
              type="submit"
              className="w-[70%] py-3 px-4 border border-transparent rounded-3xl shadow-sm text-sm font-medium text-white bg-[#c1204b] hover:bg-[#8d1737] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Send Verification Email
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SendVerificationEmail;
